.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f5f6fa;
}

.outer-container {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inner-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
}

.inner-header-container h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
}

.inner-header-container h3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #555;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.email-label, .password-label {
    width: 100%;
    max-width: 350px;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
    color: #555;
    margin-bottom: 8px;
    text-align: left;
}

.email-input, .password-input {
    width: 100%;
    max-width: 350px;
    height: 40px;
    margin-bottom: 20px;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: rgb(241, 241, 241);
    outline: none;
    transition: border-color 0.3s ease;
}

.email-input:focus, .password-input:focus {
    border-color: #6c63ff;
}

.forgot-password, .sign-up-label {
    color: #6c63ff;
    font-size: 0.9rem;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
}

.forgot-password:hover, .sign-up-label:hover {
    text-decoration: underline;
}

.sign-in-button {
    width: 100%;
    max-width: 350px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    background-color: #fcba03;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
}

.sign-in-button:hover {
    background-color: rgb(222, 172, 58);
}

.or-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: #888;
    width: 100%;
}

.or-container::before {
    content: "";
    width: 100%;
    max-width: 200px;
    height: 1px;
    background-color: #ddd;
    margin-bottom: 1rem;
}

.or-container button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.or-container button:hover {
    background-color: #f1f1f1;
}

.page-container p {
    margin-top: 20px;
    color: #555;
    font-size: 0.9rem;
}

.sign-up{
    margin-top: 20px;
    color: #555;
    font-size: 0.9rem;
}
