@import "MyRecipes.css";
.show-more-button {
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    background-color: #fcba03;
    transition: background-color 0.3s ease;
}

.show-more-button:hover {
    background-color: rgb(222, 172, 58);
}