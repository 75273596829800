.user-profile-container{
    width:70px;
    height:70px;
    border-radius:50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: white;
    display: flex;
    left: 1rem;
    top: 2rem;
    margin-left: 10px;
}

.user-profile-container:hover{
    background-color: rgb(240,240,240);
    transition: background-color 1s;
}

.profile-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #03cefc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 1.5rem;
    object-fit: cover;
}





