.home-button-container{
    width:70px;
    height:70px;
    border-radius:50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: white;
    display: flex;
    left: 1rem;
    top: 2rem;
    margin-left: 10px;

}

.home-button-container:hover{
    background-color: rgb(240,240,240);
    transition: background-color 1s;
}

.home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #fcba03;
    border-radius: 50%; /* Circular button */
    width: 40px; /* Button size */
    height: 40px; /* Button size */
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 1.5rem; /* Icon size */
}


.home-icon {
    color: #333; /* Icon color */
}

.button-text {
    display: none; /* Hide text for small buttons */
}


