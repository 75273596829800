.page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: white;
    padding: 20px;
}

.inner-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.photo-container {
    width: 40%;
    height: 80vh;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #f9f9f9;
    border-radius: 8px;
}

.placeholder-text {
    color: #999;
    font-size: 18px;
    text-align: center;
}

.recipe-details-container {
    width: 55%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
}

.image-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}



.submit-button {
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    background-color: #fcba03;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: rgb(222, 172, 58);
}