.page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: white;
    padding: 20px;
}

.inner-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.photo-container {
    width: 40%;
    height: 80vh;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    border-radius: 8px;
}

.recipe-image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.recipe-details-container {
    width: 55%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
}

h1{
    font-size: 42px;
    margin-top: 0;
}

h2{
    font-size: 24px;
}

.recipe-text{
    font-size: 18px !important;
    margin-top: 50px !important;
}



.recipe-details-header{
    display: flex;
    height: 50px;
    justify-content: right;
    width: 100%;
    align-items: center;
}



