.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f6fa;
}

.outer-container {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inner-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
}

.inner-header-container h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
}

.inner-header-container h3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #555;
}

.verify-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.verification-code-label {
    width: 100%;
    max-width: 350px;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
    color: #555;
    margin-bottom: 8px;
    text-align: left;
}

.verification-code-input {
     width: 100%;
     max-width: 350px;
     height: 40px;
     margin-bottom: 20px;
     padding: 0.8rem;
     font-size: 1rem;
     border: 1px solid #ddd;
     border-radius: 8px;
     background-color: rgb(241, 241, 241);
     outline: none;
     transition: border-color 0.3s ease;
 }

.verification-code-input:focus {
    border-color: #6c63ff;
}


.complete-button {
    width: 100%;
    max-width: 350px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    background-color: #fcba03;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
}

.complete-button:hover {
    background-color: rgb(222, 172, 58);
}