.page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: white;
    padding: 20px;
}

.recipes-grid {
    gap: 16px;
    padding: 50px;
    display: flex;
    text-align: initial;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: space-around;
}

.recipe-card {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    border: solid 1px lightgray;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 320px;
    transition: 0.5s;
}

.recipe-card:hover {
    transform: scale(1.05);
    background-color: #f2f2f2;
    filter: brightness(0.97);
}

.recipe-card:hover
#edit-button{
    display: flex;
}

.recipe-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.recipe-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 10px;
}

.title-text, .serves-text {
    text-align: left;
    margin-left: 10px;
    width: 80%;
}

.title-text{
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;

}

.serves-text{
    font-size: 14px;

}


#edit-button {
    position: absolute;
    bottom: 10px;
    right: 25px;
    background-color: #fcba03;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

#edit-button:hover {
    background-color: #f2a800;
}

