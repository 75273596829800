.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0.5rem;
    border-radius: 50px;
    background-color: rgb(241,241,241);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-input {
    width: 60%;
    height: 50px;
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-size: 1rem;
    background-color: rgb(241,241,241);

}

.search-input:hover {
    background-color: rgb(225,225,225);
    transition: background-color 1s;

}

.search-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0 1rem;
}

.search-button .material-symbols-outlined {
    font-size: 1.5rem;
    color: #333;
}
