.items-table {
    width: 100%;
    border-collapse: collapse;
}

.items-table th, .items-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.items-table th {
    background-color: #f2f2f2;
}

.list-checkbox{
    align-self: center;
    justify-self: right;
}