.favourite-btn{
    width:120px;
    height:50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: rgb(242,189,65);
    border-radius: 20px;
    display: flex;
    left: 1rem;
    top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.favourite-btn:hover{
    background-color: rgb(214,174,80);
    transition: background-color 1s;
}